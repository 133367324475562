<template>
  <RegPalletComponent goods-type="S0102/S0104" />
</template>
<script>
import RegPalletComponent from './RegPalletComponent'
export default {
  name: 'RegSemiP',
  components: {
    RegPalletComponent
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null
    }
  },
  created () {

  },
  mounted () {
  },
  updated () {
  },
  methods: {
  }
}
</script>

<style lang="scss">

</style>
